import { FixedNumber } from '@ethersproject/bignumber'
import { getFullDecimalMultiplier } from './getFullDecimalMultiplier'

export const FIXED_ZERO = FixedNumber.from(0)
export const FIXED_ONE = FixedNumber.from(1)
export const FIXED_TWO = FixedNumber.from(2)

export const FIXED_TEN_IN_POWER_18 = FixedNumber.from(getFullDecimalMultiplier(18))

export const masterChefAddresses = {
  97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
  // 97: '0x898CA22DF0f4CD318C80732419837A3E0BB8B6Bf',
  // 56: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',  // pancake
  // 56: '0x39190f2d9262e35a914f4d5feaddd6fd707a4f90',
  56: '0x09985EFFD4CecE640Dd7c15B7Afd09F2F4cD4076',
}

export const nonBSCVaultAddresses = {
  5: '0x8CB958bBdb45597cc918147469eb650A9397aBDA',
}
