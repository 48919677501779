/* eslint-disable no-console */
import { Box, Button, Flex, InjectedModalProps, LinkExternal, Message, Skeleton, Text } from '@pancakeswap/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@pancakeswap/localization'
import { useEffect, useState } from 'react'
import MyInviteTable from 'views/Info/components/InfoTables/MyInviteTable'
import { TTBASE_URL, TTTokenKey } from 'config/constants/api'
import axiosHelper from 'utils/request'
import MyAchieveTable from 'views/Info/components/InfoTables/MyAchiveTable'

const MyAchieveView = () => {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const [yourInviteCode, setYourInviteCode] = useState('')
  const [inviteList, setInviteList] = useState([])

  useEffect(() => {
    async function getData() {
      try {
        const url = `${TTBASE_URL}/user/api/v1/info/getOwner`
        const userInfo: any = await axiosHelper.get(url)
        console.log('userInfo', userInfo)
        const inviteCode = userInfo.data?.inviteCode || ''
        setYourInviteCode(inviteCode)
      } catch (error) {
        console.log('inviteCode error', error)
      }
    }
    if (account) {
      getData().then()
    }
  }, [account])

  useEffect(() => {
    async function getInviteList() {
      try {
        const url = `${TTBASE_URL}/user/api/v1/power/invitationTotal?pageNum=1&pageSize=1000`
        const inviteArr: any = await axiosHelper.get(url)
        console.log('invitationTotal', inviteArr)
        // const inviteArr = {
        //   code: 200,
        //   msg: '',
        //   data: [
        //     {
        //       address: '0xf4c2e515cffa521c7e0b5678f48ab1bca908cb31',
        //       stake: 1000.0,
        //       amount: 1e-18,
        //     },
        //   ],
        // }
        // setYourInviteCode(inviteCode)
        if (inviteArr.data && inviteArr.data.length > 0) {
          inviteArr.data = inviteArr.data.map((item: any, index: number) => {
            return {
              ...item,
              address: `0x......${item.address.substr(-4)}`
            }
          })
          setInviteList(inviteArr.data)
        }
      } catch (error) {
        console.log('invitationTotal error', error)
      }
    }
    if (account) {
      getInviteList().then()
    }
  }, [account])
  return <>{inviteList?.length > 0 ? <MyAchieveTable tokenDatas={inviteList} /> : t('No records')}</>
}

export default MyAchieveView
