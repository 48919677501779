/* eslint-disable no-console */
/* eslint-disable no-unneeded-ternary */
import { useState, useCallback, useMemo, useEffect } from 'react'
import {
  Text,
  Button,
  ButtonMenu,
  ButtonMenuItem,
  CloseIcon,
  Heading,
  IconButton,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalHeader as UIKitModalHeader,
  ModalTitle,
  ModalProps,
  Flex,
  useToast,
  Input,
} from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'
import { useWeb3React } from '@pancakeswap/wagmi'

const ModalHeader = styled(UIKitModalHeader)`
  background: ${({ theme }) => theme.colors.gradientBubblegum};
`
const InviteCodeModal = ({ confirmClick, onDismiss }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()

  const bunnyCode = sessionStorage.getItem('bunnyCode')
  const urlCode = bunnyCode ? bunnyCode : 'EPtbRRD6hEz'
  const [inviteCode, setInviteCode] = useState(urlCode)

  const handleActive = () => {
    console.log('inviteCode', inviteCode)
    confirmClick(inviteCode)
  }

  const onChangeInvite = (e) => {
    setInviteCode(e.target.value)
  }

  const handleClose = () => {
    // eslint-disable-next-line no-restricted-globals
    location.href = '/'
    onDismiss()
  }
  return (
    <ModalContainer $minWidth="375px">
      <ModalHeader>
        <ModalTitle>
          <Heading>{t('Login')}</Heading>
        </ModalTitle>
        <IconButton variant="text" onClick={handleClose}>
          <CloseIcon width="24px" color="text" />
        </IconButton>
      </ModalHeader>
      <ModalBody maxWidth="375px" width="100%">
        <Flex padding="20px" flexDirection="column">
          <Text mb="10px" fontSize="14px" color="textSubtle">
            {t(
              'Authenticated login required。',
            )}
          </Text>
          <Input placeholder={t('Input Invite Code')} value={inviteCode} onChange={onChangeInvite} />
          <Button
            mt="20px"
            minWidth="100%" // Bypass the width="fit-content" on Links
            disabled={inviteCode.length <= 0}
            onClick={handleActive}
          >
            {t('Login')}
          </Button>
        </Flex>
      </ModalBody>
    </ModalContainer>
  )
}

export default InviteCodeModal
