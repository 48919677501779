/* eslint-disable no-console */
import events from 'events'
import axios from 'axios'
import { TTBASE_URL, TIMEOUT, TTTokenKey, timerReqEventName, timerResEventName } from 'config/constants/api'

export const eventEmitterBunny = new events.EventEmitter()

let timerReq: any
let timerRes: any
const axiosHelper = axios.create({
  baseURL: TTBASE_URL,
  timeout: TIMEOUT,
})

axiosHelper.interceptors.request.use(
  (config: any) => {
    if (config.url.indexOf('login') >= 1 || config.url.indexOf('check') >= 1) {
      //
    } else {
      const tttokenString = localStorage.getItem(TTTokenKey)
      if (tttokenString && tttokenString.length > 100) {
        const tttokenObj = JSON.parse(tttokenString)
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = tttokenObj.access_token
      } else {
        if (timerReq) {
          clearTimeout(timerReq)
        }
        timerReq = setTimeout(() => {
          // console.log('configUrl===>', config.url)
          // console.log('req no token emit timerReqEventName', tttokenString)
          eventEmitterBunny.emit(timerReqEventName)
        }, 300)
      }
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  },
)

// 200	成功
// 404	未找到接口
// 400	参数不正确
// 500	未知错误请联系管理员
// 10000	登录验证超出有效时间
// 10001	验签未通过
// 10002	用户已被冻结
// 10003	令牌为空
// 10004	令牌已过期或验证不正确
// 10005	令牌验证失败
// 10006	登录状态已过期
// 10007	未登录
// 10008	没有操作权限
// 10009	用户不存在
// 10010	用户已被邀请

axiosHelper.interceptors.response.use(
  // eslint-disable-next-line consistent-return
  (res) => {
    if ([500, 10001, 10002, 10004, 10005, 10006, 10007, 10008, 10009].indexOf(res.data.code) > -1) {
      console.log('res token fail', res.data.code)
      if (timerRes) {
        clearTimeout(timerRes)
      }
      timerRes = setTimeout(() => {
        eventEmitterBunny.emit(timerResEventName)
      }, 300)
    }
    // if (res.data.code === 10003) {
    //   console.log('res token null')
    //   if (timerRes) {
    //     clearTimeout(timerRes)
    //   }
    //   timerRes = setTimeout(() => {
    //     eventEmitterBunny.emit(timerResEventName)
    //   }, 300)
    // }
    return res.data
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          console.log('请求错误')

          break
        case 401:
          console.log('未授权访问')
          break
        default:
          console.log('其他错误信息')
      }
    }
    return err
  },
)

export default axiosHelper

//
