/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useWeb3React } from '@pancakeswap/wagmi'
import Web3 from 'web3'
import { createStore, Store } from 'redux'
import { timerReqEventName, timerResEventName, TTBASE_URL, TTTokenKey } from 'config/constants/api'

import { NextLinkFromReactRouter } from 'components/NextLink'
import { Menu as UikitMenu, useModal, useToast } from '@pancakeswap/uikit'
import { useTranslation, languageList } from '@pancakeswap/localization'
import PhishingWarningBanner from 'components/PhishingWarningBanner'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import useTheme from 'hooks/useTheme'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import { usePhishingBannerManager } from 'state/user/hooks'
import axiosHelper, { eventEmitterBunny } from 'utils/request'
import { useMenuItems } from './hooks/useMenuItems'
import GlobalSettings from './GlobalSettings'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { footerLinks } from './config/footerConfig'
import { SettingsMode } from './GlobalSettings/types'

import UserMenu from './UserMenu'

const Menu = (props) => {
  const { isDark, setTheme } = useTheme()
  const cakePriceUsd = useCakeBusdPrice({ forceMainnet: true })
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useRouter()
  const { provider } = useActiveWeb3React()
  const { account } = useWeb3React()

  // const [showPhishingWarningBanner] = usePhishingBannerManager()
  const showPhishingWarningBanner = false

  const menuItems = useMenuItems()

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems as any, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  const toggleTheme = useMemo(() => {
    return () => setTheme(isDark ? 'light' : 'dark')
  }, [setTheme, isDark])

  const getFooterLinks = useMemo(() => {
    return footerLinks(t)
  }, [t])



  return (
    <>
      <UikitMenu
        linkComponent={(linkProps) => {
          return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
        }}
        leftSide={
          <>
            {/* 链接钱包 当作左侧user图标 */}
            <UserMenu />
          </>
        }
        rightSide={
          <>
            {/* 切换链路 */}
            <NetworkSwitcher />
            {/* <img className="rightSideImg scanImg" src="../images/dswap/scanner.png" alt="" />
            <img className="rightSideImg kefu" src="../images/dswap/headset.png" alt="" />
            <img className="rightSideImg notify" src="../images/dswap/notification.png" alt="" />
            <img className="rightSideImg payImg" src="../images/dswap/pay.png" alt="" /> */}
            {/* 头部导航 */}
            {/* 头部设置 */}
            {/* <GlobalSettings mode={SettingsMode.GLOBAL} /> */}
            {/* 链接钱包 右侧 */}
            {/* <UserMenu /> */}
          </>
        }
        banner={showPhishingWarningBanner && typeof window !== 'undefined' && <PhishingWarningBanner />}
        isDark={isDark}
        toggleTheme={toggleTheme}
        // currentLang={currentLanguage.code}
        langs={languageList}
        setLang={setLanguage}
        cakePriceUsd={cakePriceUsd}
        links={menuItems}
        subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
        footerLinks={getFooterLinks}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href}
        buyCakeLabel={t('Buy BUY')}
        {...props}
      />
    </>
  )
}

export default Menu
