import { ChainId, Token, WBNB } from '@pancakeswap/sdk'
import { USDC_GOERLI } from './common'

export const baseTokens = {
  weth: WBNB[ChainId.BASE],
  MOCHI: new Token(
    ChainId.BASE,
    '0xa4220a2b0cb10bf5fdc3b8c3d9e13728f5e7ca56',
    8,
    'MOCHI',
    'MOCHI',
    'https://bunnyswap.co/images/tokens/0xa4220a2b0cb10bf5fdc3b8c3d9e13728f5e7ca56.png',
  ),
  RCKT: new Token(
    ChainId.BASE,
    '0x6653dd4b92a0e5bf8ae570a98906d9d6fd2eec09',
    18,
    'RCKT',
    'RCKT',
    'https://bunnyswap.co/images/tokens/0x6653dd4b92a0e5bf8ae570a98906d9d6fd2eec09.png',
  ),
}
