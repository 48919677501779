/* eslint-disable no-console */
import { Box, Button, Flex, InjectedModalProps, LinkExternal, Message, Skeleton, Text, useToast, useModal } from '@pancakeswap/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@pancakeswap/localization'
import { useEffect, useState } from 'react'
import Web3 from 'web3'
import MyInviteTable from 'views/Info/components/InfoTables/MyInviteTable'
import { timerReqEventName, timerResEventName, TTBASE_URL, TTTokenKey } from 'config/constants/api'
import axiosHelper, { eventEmitterBunny } from 'utils/request'
import CopyInviteAddress from './CopyInviteAddress'
import InviteCodeModal from '../inviteCodeModal'
import LoginModal from '../loginModal'

const InviteCodeView = () => {
  const { t } = useTranslation()
  const { account, chainId, provider } = useActiveWeb3React()
  const [yourInviteCode, setYourInviteCode] = useState('')
  const [inviteList, setInviteList] = useState([])
  const { toastError } = useToast()
  // @ts-ignore
  const [handleInviteCode] = useModal(<InviteCodeModal confirmClick={signLogin} />, false)
  // @ts-ignore
  const [handleLogin] = useModal(<LoginModal confirmClick={signLogin} />, false)

  useEffect(() => {
    if (account) {
      // console.log('menu on')
      const reqEvent = () => {
        // 用户登录Login
        if (account) {
          // console.log('menu toLoginSign timerReqEventName')
          checkUserAndSignLogin(account).then()
        }
      }
      // @ts-ignore
      eventEmitterBunny._events[timerReqEventName] = []
      eventEmitterBunny.on(timerReqEventName, reqEvent)
      // console.log('eventEmitterBunny===>', eventEmitterBunny)
      const resEvent = () => {
        if (account) {
          // console.log('menu toLoginSign timerResEventName')
          checkUserAndSignLogin(account).then()
        }
      }
      // @ts-ignore
      eventEmitterBunny._events[timerResEventName] = []
      eventEmitterBunny.on(timerResEventName, resEvent)
    }
  }, [account])

  async function checkUserAndSignLogin(accountStr: string) {
    // 有无用户
    const url = `${TTBASE_URL}/user/api/v1/check?address=${accountStr}`
    const checkUserRes: any = await axiosHelper.get(url)
    if (checkUserRes && checkUserRes.code === 200) {
      if (checkUserRes.data === false) {
        // 不是我们用户 填写邀请码
        // inviteCode: 'EPD9AMJc7gu',
        handleInviteCode()
      } else {
        handleLogin()
        // 是我们用户
        // await signLogin()
      }
    } else {
      toastError(t('Error'), checkUserRes?.data?.msg || JSON.stringify(checkUserRes))
    }
  }

  async function signLogin(inviteCode = '') {
    const time = new Date().getTime()
    let loginForm = {
      address: account,
      randomHex: '',
      time,
      inviteCode,
      r: '',
      s: '',
      v: '',
    }
    const ethereumHelper = (window as any).ethereum || false
    if (provider && ethereumHelper && account) {
      const nodeurl = (provider as any)?.connection?.url || ''
      const web3 = new Web3(ethereumHelper)
      const randomHex = web3.utils.randomHex(32)
      const loginInfoStr = account + randomHex + time
      const hash = web3.utils.keccak256(loginInfoStr)
      console.log('hash===>', hash)
      console.log('account===>', account)
      // @ts-ignore
      const signature = await web3.eth.personal.sign(hash, account, (err: any) => {
        if (err) {
          console.error(err)
        }
      })
      console.log('signature===>', signature)
      const signature1 = signature.substring(2)
      const r = `0x${signature1.substring(0, 64)}`
      const s = `0x${signature1.substring(64, 128)}`
      const v = `0x${signature1.substring(128, 130)}`
      loginForm = {
        ...loginForm,
        randomHex,
        r,
        s,
        v,
      }
      const url = `${TTBASE_URL}/user/api/v1/login`
      const res: any = await axiosHelper.post(url, loginForm)
      if (res.code === 200) {
        localStorage.setItem(TTTokenKey, JSON.stringify(res.data))
        localStorage.setItem('TTACCOUNT', account)
        setTimeout(() => {
          // eslint-disable-next-line no-restricted-globals
          location.reload()
        }, 500)
      } else {
        toastError(t('Error'), JSON.stringify(res?.msg || res))
      }
    }
  }

  useEffect(() => {
    async function getInviteList() {
      try {
        const url = `${TTBASE_URL}/user/api/v1/relation/ownerList?pageNum=1&pageSize=1000`
        const inviteArr: any = await axiosHelper.get(url)
        // setYourInviteCode(inviteCode)
        if(inviteArr.data && inviteArr.data.length > 0) {
          setInviteList(inviteArr.data)
        }
      } catch (error) {
        console.log('invitationTotal error', error)
      }
    }
    if (account) {
      getInviteList().then()
    }
  }, [account])

  useEffect(() => {
    async function getData() {
      try {
        const url = `${TTBASE_URL}/user/api/v1/info/getOwner`
        const userInfo: any = await axiosHelper.get(url)
        const inviteCode = userInfo.data?.inviteCode || ''
        setYourInviteCode(inviteCode)
      } catch (error) {
        console.log('inviteCode error', error)
      }
    }
    getData().then()
  }, [])

  return (
    <>
      <Text color="secondary" fontSize="12px" textTransform="uppercase" fontWeight="bold" mb="8px">
        {t('My Invite Code')}
      </Text>
      <CopyInviteAddress account={yourInviteCode} mb="24px" />
      {inviteList.length > 0 ? <MyInviteTable tokenDatas={inviteList} /> : t('No records')}
    </>
  )
}

export default InviteCodeView
