import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  @font-face {
    font-family: 'iconfont'; 
    src: url('//at.alicdn.com/t/c/font_3085910_t5oe8d1gze.woff2?t=1677737716397') format('woff2'),
         url('//at.alicdn.com/t/c/font_3085910_t5oe8d1gze.woff?t=1677737716397') format('woff'),
         url('//at.alicdn.com/t/c/font_3085910_t5oe8d1gze.ttf?t=1677737716397') format('truetype');
  }
  .iconfont{
    font-family:"iconfont" !important;
    font-size:16px;font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
  }
  .dtest {
    border: 1px solid red !important;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  .rightSideImg {
    width: 18px;
    height: auto;
    margin-right: 22px;
    cursor: pointer;
  }
  .rightSideImg:hover {
    opacity: 0.65;
  }
  .payImg {
    margin-right: 0;
  }
  body {
    // background: #FFFFFF;
  }

  .coinNavView::-webkit-scrollbar {
    display: none;
  }

  // 去掉滚动条
  // ::-webkit-scrollbar {
  //   display: none;
  // }
`

export default GlobalStyle
