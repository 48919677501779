import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
import { Token } from "./entities/token";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId["ETHEREUM"] = 1] = "ETHEREUM";
    ChainId[ChainId["RINKEBY"] = 4] = "RINKEBY";
    ChainId[ChainId["GOERLI"] = 5] = "GOERLI";
    ChainId[ChainId["BSC"] = 56] = "BSC";
    ChainId[ChainId["BSC_TESTNET"] = 97] = "BSC_TESTNET";
    ChainId[ChainId["BASE"] = 8453] = "BASE";
    ChainId[ChainId["CCTC"] = 1331] = "CCTC";
})(ChainId || (ChainId = {}));
export var ChainIdIdo = [
    {
        ChainId: 56,
        ChainString: "0x38",
        ChainName: "BSC"
    },
    {
        ChainId: 97,
        ChainString: "0x61",
        ChainName: "BSCTest"
    },
    {
        ChainId: 8453,
        ChainString: "0x2105",
        ChainName: "BASE"
    },
    {
        ChainId: 84531,
        ChainString: "0x14a33",
        ChainName: "BASETest"
    }
];
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
// 56
export var FACTORY_ADDRESS = "0x3748cF7d937F66A5A4EA9e3e9F1a780f371C65Df";
// TODO: ETH This is test version, do not depends on it
var FACTORY_ADDRESS_ETH = "0xD93801d7D3a368D94A3A32E97A20f7aC1948a5dB";
var _obj;
// bunnyswap Factory合约
export var FACTORY_ADDRESS_MAP = (_obj = {}, _define_property(_obj, ChainId.ETHEREUM, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.RINKEBY, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.GOERLI, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.BSC, FACTORY_ADDRESS), _define_property(_obj, ChainId.BSC_TESTNET, "0x0f5ADDD527b23E107712C848354B426F3cE75065"), _define_property(_obj, ChainId.BASE, "0x7A837F745DAA97f159FF2Db3d6c441024148C980"), _define_property(_obj, ChainId.CCTC, "0x907B6407D27e2Fa130Eb470743cFdaF801b51838"), _obj);
// bunnyswap HASH
export var INIT_CODE_HASH = "0xed93185e39fc35aa6de9e8edccd5132081288cede00d0a8b9a2ebf9a2c53ab37";
var INIT_CODE_HASH_ETH = "0x57224589c67f3f30a6b0d7a1b54cf3153ab84563bc609ef41dfb34f8b2974d2d";
var _obj1;
export var INIT_CODE_HASH_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.ETHEREUM, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.RINKEBY, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.GOERLI, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.BSC, INIT_CODE_HASH), _define_property(_obj1, ChainId.BSC_TESTNET, "0x5164df55a4f5ddc3dcc81aeb0eca7726ac43f9702289b1c8c50f83cdab3e0908"), _define_property(_obj1, ChainId.BASE, "0x2d0a6bcdaddfe3a381690bf816f9dfaefec3aac1313d1ba82f78c8618bcf48f5"), _define_property(_obj1, ChainId.CCTC, "0x72fd15b2c34429b781b620d462978714c06eb7004afbb0bb7b63891e585a3d37"), _obj1);
var _obj2;
// pancake Factory合约
export var FACTORY_ADDRESS_MAP_PANCAKE = (_obj2 = {}, _define_property(_obj2, ChainId.ETHEREUM, FACTORY_ADDRESS_ETH), _define_property(_obj2, ChainId.RINKEBY, FACTORY_ADDRESS_ETH), _define_property(_obj2, ChainId.GOERLI, FACTORY_ADDRESS_ETH), _define_property(_obj2, ChainId.BSC, "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73"), _define_property(_obj2, ChainId.BSC_TESTNET, "0x6725f303b657a9451d8ba641348b6761a6cc7a17"), _define_property(_obj2, ChainId.BASE, "0x7A837F745DAA97f159FF2Db3d6c441024148C980"), _define_property(_obj2, ChainId.CCTC, "0x907B6407D27e2Fa130Eb470743cFdaF801b51838"), _obj2);
var _obj3;
// pancake HASH
export var INIT_CODE_HASH_MAP_PANCAKE = (_obj3 = {}, _define_property(_obj3, ChainId.ETHEREUM, INIT_CODE_HASH_ETH), _define_property(_obj3, ChainId.RINKEBY, INIT_CODE_HASH_ETH), _define_property(_obj3, ChainId.GOERLI, INIT_CODE_HASH_ETH), _define_property(_obj3, ChainId.BSC, "0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5"), _define_property(_obj3, ChainId.BSC_TESTNET, "0xd0d4c4cd0848c93cb4fd1f498d7013ee6bfb25783ea21593d5834f5d250ece66"), _define_property(_obj3, ChainId.BASE, "0x2d0a6bcdaddfe3a381690bf816f9dfaefec3aac1313d1ba82f78c8618bcf48f5"), _define_property(_obj3, ChainId.CCTC, "0x72fd15b2c34429b781b620d462978714c06eb7004afbb0bb7b63891e585a3d37"), _obj3);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var _9975 = JSBI.BigInt(9975);
export var _10000 = JSBI.BigInt(10000);
export var MaxUint256 = JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj4;
export var SOLIDITY_TYPE_MAXIMA = (_obj4 = {}, _define_property(_obj4, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj4, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj4);
var _obj5;
export var WETH9 = (_obj5 = {}, _define_property(_obj5, ChainId.ETHEREUM, new Token(ChainId.ETHEREUM, "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj5, ChainId.RINKEBY, new Token(ChainId.RINKEBY, "0xc778417E063141139Fce010982780140Aa0cD5Ab", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj5, ChainId.GOERLI, new Token(ChainId.GOERLI, "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6", 18, "WETH", "Wrapped Ether", "https://weth.io")), _obj5);
var _obj6;
export var WBNB = (_obj6 = {}, _define_property(_obj6, ChainId.ETHEREUM, new Token(ChainId.ETHEREUM, "0x418D75f65a02b3D53B2418FB8E1fe493759c7605", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _define_property(_obj6, ChainId.BSC, new Token(ChainId.BSC, "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _define_property(_obj6, ChainId.BSC_TESTNET, new Token(ChainId.BSC_TESTNET, "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _define_property(_obj6, ChainId.BASE, new Token(ChainId.BASE, "0x4200000000000000000000000000000000000006", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj6, ChainId.CCTC, new Token(ChainId.CCTC, "0xca2aedC6c8E9881DF228519e95045CbbBf342c20", 18, "WOPCC", "Wrapped Opcc", "https://weth.io")), _obj6);
var _obj7;
export var WNATIVE = (_obj7 = {}, _define_property(_obj7, ChainId.ETHEREUM, WETH9[ChainId.ETHEREUM]), _define_property(_obj7, ChainId.RINKEBY, WETH9[ChainId.RINKEBY]), _define_property(_obj7, ChainId.GOERLI, WETH9[ChainId.GOERLI]), _define_property(_obj7, ChainId.BSC, WBNB[ChainId.BSC]), _define_property(_obj7, ChainId.BSC_TESTNET, WBNB[ChainId.BSC_TESTNET]), _define_property(_obj7, ChainId.BASE, WBNB[ChainId.BASE]), _define_property(_obj7, ChainId.CCTC, WBNB[ChainId.CCTC]), _obj7);
var _obj8;
export var NATIVE = (_obj8 = {}, _define_property(_obj8, ChainId.ETHEREUM, {
    name: "Ether",
    symbol: "ETH",
    decimals: 18
}), _define_property(_obj8, ChainId.RINKEBY, {
    name: "Rinkeby Ether",
    symbol: "RIN",
    decimals: 18
}), _define_property(_obj8, ChainId.GOERLI, {
    name: "Goerli Ether",
    symbol: "GOR",
    decimals: 18
}), _define_property(_obj8, ChainId.BSC, {
    // name: 'Binance Chain Native Token',
    name: "BSC",
    symbol: "BNB",
    decimals: 18
}), _define_property(_obj8, ChainId.BSC_TESTNET, {
    name: "Binance Chain Native Token",
    symbol: "tBNB",
    decimals: 18
}), _define_property(_obj8, ChainId.BASE, {
    name: "BASE",
    symbol: "ETH",
    decimals: 18
}), _define_property(_obj8, ChainId.CCTC, {
    name: "CCTC",
    symbol: "OPCC",
    decimals: 18
}), _obj8);
