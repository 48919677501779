export const apiTT = ''
export const TTTokenKey = 'TTTOKEN'

// const devBaseURL = 'http://192.168.90.201:18080'
const devBaseURL = 'https://api.bunnyswap.co'
const proBaseURL = 'https://api.bunnyswap.co'

export const TTBASE_URL_IDO = 'https://api2.bunnyswap.co'

export const TTBASE_URL = process.env.NODE_ENV === 'development' ? devBaseURL : proBaseURL

export const TTWS_URL = 'wss://wss.bunnyswap.co/ws'

export const TIMEOUT = 20 * 1000

export const timerReqEventName = 'timerReq toLoginSign'
export const timerResEventName = 'timerRes toLoginSign'
