import {
  MenuItemsType,
  EarnFillIcon,
  EarnIcon,
  DropdownMenuItems,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { NavHome, NavHomeActive, NavMarket, NavMarketActive, NavTrade, NavSwap, NavTradeActive, NavSwapActive, NavFarms, NavFarmsActive } from './navImgs'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

// 移动端底部导航配置 tabbar
const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    // {
    //   label: t('Home'),
    //   href: '/home',
    //   icon: NavHome,
    //   fillIcon: NavHomeActive,
    //   showItemsOnMobile: false,
    //   image: '../images/dswap/nav-home.png',
    //   items: [

    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: t('Swap'),
    //   href: '/swap',
    //   icon: NavTrade,
    //   fillIcon: NavTradeActive,
    //   showItemsOnMobile: false,
    //   image: '../images/dswap/nav-trade.png',
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
