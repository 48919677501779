/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
import { useEffect, useState } from 'react'

export const useSessionStorageSwap = (keyName: string, defaultValue: string) => {
  if (typeof window !== 'undefined') {
    const value = window.sessionStorage.getItem(keyName)

    if(value){
      return value
    }else{
      const storage = window.sessionStorage.setItem(keyName, defaultValue)
      return storage
    }
  }
}
