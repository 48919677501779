import { multicallAddresses } from '@pancakeswap/multicall'

export default {
  masterChef: {
    97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    // 97: '0xbfc693c6baa76ae1d176055dd8a794827f73d301',
    // 56: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652', // pancake
    // 56: '0x39190f2d9262e35a914f4d5feaddd6fd707a4f90',
    56: '0x09985EFFD4CecE640Dd7c15B7Afd09F2F4cD4076',
    5: '0x8CB958bBdb45597cc918147469eb650A9397aBDA',
    8453: '0x4200000000000000000000000000000000000006',
    1331: '0xca2aedC6c8E9881DF228519e95045CbbBf342c20',
  },
  masterChefV1: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  },
  multiCall: multicallAddresses,
  znbStaking: {
    56: '0x278bee2AB6799F4ee8e6c3C49d71e576a36e402b',
    97: '0xb8E35283D2F95bD0B34BAF40b1517082c84Ff39B',
  },
  znb: {
    56: '0x4a74361eb91d8f49831a27e92ffcfd0345f272b6',
    97: '0xd66f7c09FDBc50edcA645400F241eEF0F82963C8',
  },
  bunnyNft: {
    56: '0xfBF94F996e7a78259B8C8D1b8Ee764b5d3301BC4',
    97: '0x997526b491219aa50b783485705B0eBc0AC62993',
  },
  nftReward: {
    56: '0x68E5a0a116393E513FddD2435f4C7Ad4440f5EC7',
    97: '0x72347C6cc99cf91BfD2e4aD0AFFABBb5dd87A66A',
  },
  buy: {
    56: '0xedA1d62E9d92BA48C9628CC40e9d9C991ac8edfb',
    97: '0xd66f7c09FDBc50edcA645400F241eEF0F82963C8',
  },
  pcRouter: {
    56: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    97: '0xc21Ad7c35708D08C4aCaa6295115D346d7CCE6c9',
  },
  feeRouter: {
    56: '0xE8aCB26333902f21abfC167E0BA3457Fc8DF9E0B',
    97: '0xc1dfd92a5c3acf75c869167030a9b9a15fbc5af5',
    8453: '0x0a8E40eFF1220F5be5a33709549735D1BC67eB3F',
    1331: '0xeA64aBc9E08bA6b15fD098C32E971fcB05c2668A',
  },
  feePancakeRouter: {
    56: '0xc83F150FC57A8D1D11E6021024101c024E6deF96',
    97: '0x58A96a9391f3225D9343CC79d1a997e74a3A54c7',
    8453: '0x0a8E40eFF1220F5be5a33709549735D1BC67eB3F',
    1331: '0xeA64aBc9E08bA6b15fD098C32E971fcB05c2668A',
  },
  usdt: {
    56: '0x55d398326f99059fF775485246999027B3197955',
    97: '0xDF8Bc57a0334E6920132Dc465Acb6D63b26b6b5D',
  },
  bunnyUSDT: {
    56: '0x4a74361eb91d8f49831a27e92ffcfd0345f272b6',
    97: '0xf355d01A7BF82DdD5EFaB74eb81528A3246bF067',
  },
  activeNode: {
    56: '0xfBF94F996e7a78259B8C8D1b8Ee764b5d3301BC4',
    97: '0x69CaBBB12938394feDE5A392B729194880b4443B',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeBunnies: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetitionEaster: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  tradingCompetitionFanToken: {
    56: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    97: '',
  },
  tradingCompetitionMobox: {
    56: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    97: '',
  },
  tradingCompetitionMoD: {
    56: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    97: '',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    56: '0x45c54210128a065de780C4B0Df3d16664f7f859e',
    97: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
  },
  cakeFlexibleSideVault: {
    56: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    97: '',
  },
  predictionsBNB: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '',
  },
  predictionsCAKE: {
    56: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    97: '',
  },
  chainlinkOracleBNB: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
  },
  chainlinkOracleCAKE: {
    56: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    97: '',
  },
  predictionsV1: {
    56: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    97: '',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  bunnySpecialXmas: {
    56: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    97: '',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  AnniversaryAchievement: {
    56: '0xF839286bD9D14b358496829F3BaB3145C16ad3C1',
    97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  nftMarket: {
    56: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
  },
  nftSale: {
    56: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
  },
  pancakeSquad: {
    56: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    97: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
  },
  potteryDraw: {
    56: '0x01871991587d5671f3A2d4E2BcDC22F4E026396e',
    97: '0xDB9D365b50E62fce747A90515D2bd1254A16EbB9',
  },
  // TODO: multi
  zap: {
    56: '0xD4c4a7C55c9f7B3c48bafb6E8643Ba79F42418dF',
    97: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
  },
  iCake: {
    56: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    97: '',
  },
  bCakeFarmBooster: {
    56: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    97: '',
  },
  bCakeFarmBoosterProxyFactory: {
    56: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    97: '',
  },
  powerStake: {
    56: '',
    97: '0xcBdA3209df0923Dc0B18265c087A2Ac862089fc6',
  },
  lpReward: {
    56: '0xeb8301Cfe09705CD9927A74EA63Bd43B541Fa159',
    97: '',
  },
  bunnyContract: {
    56: '0x278bee2AB6799F4ee8e6c3C49d71e576a36e402b',
    97: '',
  },
  vipUSDT: {
    56: '0x55d398326f99059fF775485246999027B3197955',
    97: '0x35790fD02477A6779F3d4142172C7743720979f8',
  },
  vipNFTContract: {
    56: '0xEfA49e2D1806a0796e5e7B219a341318574fD579',
    97: '0x58c9d686047b4f8B568aA336eE9bE3B3A3DeB94f',
  },
  vipContract: {
    56: '0xfDCcd0C1Bd507622825F1C2994E17ce71a15D825',
    97: '0x04F20e5CcE41517F3af51E540203F22fD99475f7',
  },
  currencyContract: {
    56: '0x7674970CD4608d9790c2a27DEF9626e0B8F8123A',
    97: '0xd488c0ee09182f07ddd622c0574eb10e5e01ff8c',
  },
  currencyClaimContract: {
    56: '0x7674970CD4608d9790c2a27DEF9626e0B8F8123A',
    97: '0xd488c0ee09182f07ddd622c0574eb10e5e01ff8c',
  },
  currencyToken: {
    56: '0x4a74361eb91d8f49831a27e92ffcfd0345f272b6',
    97: '0x1843E4Dd85bfdc933deFF9b5fae3A8f0B257878d',
  },
  znbstakeingContract: {
    56: '',
    97: '0xFCb4e2bE40222711651611ec69d7f3745552E43c',
  },
}
