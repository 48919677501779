import { useState, useCallback, useMemo, useEffect } from 'react'
import {
  Text,
  Button,
  CloseIcon,
  Heading,
  IconButton,
  ModalBody,
  ModalContainer,
  ModalHeader as UIKitModalHeader,
  ModalTitle,
  ModalProps,
  Flex,
  useToast,
  Input,
} from '@pancakeswap/uikit'
import { useRouter } from 'next/router'
import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'

const ModalHeader = styled(UIKitModalHeader)`
  background: ${({ theme }) => theme.colors.gradientBubblegum};
`
const LoginModal = ({ confirmClick, onDismiss }) => {
  const { t } = useTranslation()
  const { pathname } = useRouter()

  const handleActive = () => {
    // console.log('go login')
    confirmClick()
  }

  const handleClose = () => {
    // eslint-disable-next-line no-restricted-globals
    // location.href = '/power'
    if(pathname && pathname.indexOf('power') === 1){
      location.reload()
    }
    onDismiss()
  }
  return (
    <ModalContainer $minWidth="375px">
      <ModalHeader>
        <ModalTitle>
          <Heading>{t('Login')}</Heading>
        </ModalTitle>
        <IconButton variant="text" onClick={handleClose}>
          <CloseIcon width="24px" color="text" />
        </IconButton>
      </ModalHeader>
      <ModalBody maxWidth="375px" width="100%">
        <Flex padding="20px" flexDirection="column">
          <Text mb="10px" fontSize="14px" color="textSubtle">
            {t(
              'Authenticated login required。',
            )}
          </Text>
          <Button
            mt="20px"
            minWidth="100%" // Bypass the width="fit-content" on Links
            onClick={handleActive}
          >
            {t('Login')}
          </Button>
        </Flex>
      </ModalBody>
    </ModalContainer>
  )
}

export default LoginModal
