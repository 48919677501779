import addresses from 'config/constants/contracts';
import { ChainId, Token } from '@pancakeswap/sdk'

export const USDT_MAINNET = new Token(
  ChainId.CCTC,
  '0x01721B1690b735b69e2c15cBBF6cD1937334B8F5',
  18,
  'USDT',
  'USDT Token',
  'http://bunnyswap.co/',
)

export const ZNB_MAINNET = new Token(
  ChainId.BSC,
  '0x4a74361eb91d8f49831a27e92ffcfd0345f272b6',
  18,
  'ZNB',
  'ZNB Token',
  'http://bunnyswap.co/',
)

export const MOCHI_MAINNET = new Token(
  ChainId.BASE,
  '0xa4220a2b0cb10bf5fdc3b8c3d9e13728f5e7ca56',
  8,
  'MOCHI',
  'MOCHI Token',
  'http://bunnyswap.co/',
)

export const CAKE_MAINNET = new Token(
  ChainId.BSC,
  '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  18,
  'CAKE',
  'CAKE Token',
  'http://bunnyswap.co/',
)

export const BUY_MAINNET = new Token(
  ChainId.BSC,
  addresses.buy[ChainId.BSC],
  18,
  'BUY',
  'BunnySwap Token',
  'https://www.bunnyswap.co/images/bunnylogo.png',
)


export const CAKE_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0xb73C85c1c8aca26F1b3aC4863B4D658096802A84',
  18,
  'BUY',
  'BunnySwap Token',
  'http://bunnyswap.co/',
)

export const BUY_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  addresses.buy["97"],
  18,
  'BUY',
  'BunnySwap Token',
  'https://www.bunnyswap.co/images/bunnylogo.png',
)


export const USDC_BSC = new Token(
  ChainId.BSC,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x64544969ed7EBf5f083679233325356EbE738930',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_ETH = new Token(ChainId.ETHEREUM, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD Coin')

// export const USDC_RINKEBY = new Token(
//   ChainId.RINKEBY,
//   '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b',
//   6,
//   'tUSDC',
//   'test USD Coin',
// )

export const USDC_GOERLI = new Token(
  ChainId.GOERLI,
  '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDT_BSC = new Token(
  ChainId.BSC,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_ETH = new Token(
  ChainId.ETHEREUM,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const BUSD_BSC = new Token(
  ChainId.BSC,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0xE717433ce2f87244FEb01d25c203ECc261D86158',
  18,
  'BUSD',
  'Binance BUSD',
  'https://www.paxos.com/busd/',
)

export const USDT_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x44004827f2F72566E12884A38f63f72F2a5143ea',
  18,
  'USDT',
  'Binance USDT',
  'https://www.paxos.com/busd/',
)

export const BUSD_ETH = new Token(
  ChainId.ETHEREUM,
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_RINKEBY = new Token(
  ChainId.RINKEBY,
  '0x4e2442A6f7AeCE64Ca33d31756B5390860BF973E',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_GOERLI = new Token(
  ChainId.GOERLI,
  '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const WBNB_TESTNET = new Token(
  ChainId.BSC,
  '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  18,
  'WBNB',
  'Binance WBNB',
  'https://www.paxos.com/busd/',
)

export const FIL_BSC = new Token(
  ChainId.BSC,
  '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
  18,
  'FIL',
  'Binance FIL',
  'https://www.paxos.com/busd/',
)

export const BUSD: Record<ChainId, Token> = {
  [ChainId.ETHEREUM]: BUSD_ETH,
  [ChainId.RINKEBY]: BUSD_RINKEBY,
  [ChainId.GOERLI]: BUSD_GOERLI,
  [ChainId.BSC]: BUSD_BSC,
  [ChainId.BSC_TESTNET]: BUSD_TESTNET,
  [ChainId.BASE]: MOCHI_MAINNET,
  [ChainId.CCTC]: USDT_MAINNET,
}

export const CAKE = {
  [ChainId.BSC]: ZNB_MAINNET,
  [ChainId.BSC_TESTNET]: CAKE_TESTNET,
  [ChainId.BASE]: MOCHI_MAINNET,
  [ChainId.CCTC]: USDT_MAINNET,
}

export const CAKE_FARMS = {
  [ChainId.BSC]: CAKE_MAINNET,
  [ChainId.BSC_TESTNET]: CAKE_TESTNET,
}

export const USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET,
  [ChainId.ETHEREUM]: USDC_ETH,
  // [ChainId.RINKEBY]: USDC_RINKEBY,
  [ChainId.GOERLI]: USDC_GOERLI,
}

export const USDT = {
  [ChainId.BSC]: USDT_BSC,
  [ChainId.ETHEREUM]: USDT_ETH,
}

export const BNB_FARMS = {
  [ChainId.BSC]: WBNB_TESTNET,
  [ChainId.BSC_TESTNET]: USDT_TESTNET,
}

export const FIL_FARMS = {
  [ChainId.BSC]: FIL_BSC,
  [ChainId.BSC_TESTNET]: USDT_TESTNET,
}