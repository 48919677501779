import { ChainId, Token, WBNB } from '@pancakeswap/sdk'
import { USDC_GOERLI } from './common'

export const cctcTokens = {
  wopcc: WBNB[ChainId.CCTC],
  opcc: new Token(
    ChainId.CCTC,
    '0xca2aedC6c8E9881DF228519e95045CbbBf342c20',
    18,
    'OPCC',
    'OPCC',
    'https://www.binance.com/',
  ),
  // OPCC: new Token(
  //   ChainId.CCTC,
  //   '0xca2aedC6c8E9881DF228519e95045CbbBf342c20',
  //   18,
  //   'OPCC',
  //   'OPCC',
  //   'https://bunnyswap.co/images/tokens/0xca2aedC6c8E9881DF228519e95045CbbBf342c20.png',
  // ),
  USDT: new Token(
    ChainId.CCTC, 
    '0x01721B1690b735b69e2c15cBBF6cD1937334B8F5',
    18,
    'USDT',
    'USDT',
    'https://bunnyswap.co/images/tokens/0x01721B1690b735b69e2c15cBBF6cD1937334B8F5.png',
  ),
}
